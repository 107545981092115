import hu from "./amrest-franchise-hu-hu-HU.json";
// import pl from "./amrest-franchise-pl-pl-PL.json";
// import fr from "./amrest-franchise-fr-fr-FR.json";
// import cz from "./amrest-franchise-cz-cs-CZ.json";
import en from "./amrest-franchise-hu-en-US.json";

import { changeLanguageDataLayer } from "../../DataLayer";
import { changeLanguage as piwikChangeLanguage } from "../../PiwikDataLayer";

import config from "global.config";

let language = window.location.pathname.substring(0,4) === "/en/" ? "en" : config.lang;

function changeLanguage(to) {
  if (language === to) {
    return;
  }
  document.body.classList.remove(language);
  document.body.classList.add(to);
  document.documentElement.setAttribute("lang", to);

  language = language === config.lang ? "en" : config.lang;
  changeLanguageDataLayer(language);
  piwikChangeLanguage(language) // piwik changeLanguage
}

function getLanguage() {
  return language;
}

const translations = new Proxy([], {
  get: function (target, name) {
    switch (language) {
      // case "pl":
      //   return pl[name];
      // case "cz":
      //   return cz[name];
      // case "fr":
      //   return fr[name];
      case "hu":
        return hu[name];
      default:
        return en[name];
    }
  },
});

export default translations;
export { changeLanguage, getLanguage };
